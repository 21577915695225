import {DataLayer, api as NTAPI} from '@nimbus-target/ui';
import PropTypes from 'prop-types';
import {useReducer} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatchPost} from 'redux/hooks/fetch';
import Cookies from 'universal-cookie';
import {useDidMountEffect} from 'utils/hooks';
import {UserContext} from './index';

const initialState = {
	// TODO: completar
	groupType: null,
	groupId: null,
	groupSalesId: null,
	groupName: null,
	isManager: false,
	isAuthenticated: false,
	isAuthenticationChecked: false,
};

function gtag(...rest) {
	DataLayer.push(...rest.slice(1));
}

function reducer(state, action) {
	switch (action.type) {
		case 'checkAuthentication':
			return {
				...state,
				isAuthenticationChecked: true,
			};
		case 'resetAuthentication':
			return {
				...initialState,
				isAuthenticationChecked: true,
			};
		case 'setAuthentication':
			return {
				...state,
				isAuthenticated: true,
				isAuthenticationChecked: true,
			};
		case 'setUserData':
			return {...state, isAdmin: action.payload.isAdmin || false, ...action.payload};
		default:
			throw new Error();
	}
}

function UserContextProvider(props) {
	const {children} = props;

	const [state, dispatch] = useReducer(reducer, initialState);

	const history = useHistory();
	const {pathname} = useLocation();

	const post = useDispatchPost();

	const removeAuthentication = () => {
		localStorage.removeItem('jwt');
		NTAPI.setAuthToken('');
	};

	const resetAuthentication = () => {
		removeAuthentication();
		dispatch({type: 'resetAuthentication'});
	};

	const storeAuthentication = token => {
		localStorage.setItem('jwt', token);
		NTAPI.setAuthToken(token);
	};

	useDidMountEffect(() => {
		const env = window.ENV ?? process.env;
		if (env?.REACT_APP_DOMAIN_CORE) {
			console.info('v1.0.0-beta.80:', env?.REACT_APP_DOMAIN_CORE);
			NTAPI.setBaseUrl(env.REACT_APP_DOMAIN_CORE);
		}
		NTAPI.setAuthToken(localStorage.getItem('jwt') ?? '');
	});

	useDidMountEffect(() => {
		let jwt = localStorage.getItem('jwt');
		const cookies = new Cookies();
		const userCookie = cookies.get('authInfo');

		if (!jwt && pathname.includes('webphone')) {
			const jwtParam = pathname.split('/')[pathname.split('/').length - 1];
			if (jwtParam) {
				jwt = jwtParam;
				storeAuthentication(jwtParam);
			}
		}

		if (!jwt && userCookie?.auth) {
			// Added this console to confirm whether the userCookie is string or not, ensuring we know its type and value.
			console.log({
				type: typeof userCookie,
				value: userCookie,
			});

			jwt = userCookie.auth;
			storeAuthentication(userCookie.auth);
			cookies.remove('authInfo');
		}

		if (!jwt) {
			if (
				pathname.indexOf('login') === -1 &&
				pathname.indexOf('password-recover') === -1 &&
				pathname.indexOf('health') === -1 &&
				pathname.indexOf('privacy') === -1 &&
				pathname.indexOf('embed-mobile-dash') === -1
			) {
				history.push('/login');
			}
			dispatch({type: 'checkAuthentication'});
			return;
		}

		post('/user/my-info')
			.then(responseData => {
				const {data} = responseData;
				if (data.forceLogoff) {
					resetAuthentication();
					history.push('/login');
					gtag('event', 'force_logoff');
					return;
				}
				if (pathname.indexOf('login') !== -1) history.push('/chat');
				dispatch({type: 'setAuthentication'});
				dispatch({type: 'setUserData', payload: data});
				gtag('set', 'user_properties', {
					id: data._id,
					groupType: data.groupType,
				});
				const dataGA = {};
				Object.keys(data).forEach(title => {
					dataGA[`user_data_${title}`] = data[title];
				});
				gtag('event', 'set_token', dataGA);
			})
			.catch(() => {
				if (pathname.indexOf('login') === -1) {
					history.push('/login');
				}
				resetAuthentication();
				gtag('event', 'force_logoff');
			});
	});

	// set /user/my-info return
	const setUserData = data => {
		dispatch({type: 'setUserData', payload: data});
	};

	const resetToken = () => {
		resetAuthentication();
		gtag('event', 'reset_token');
	};

	const setToken = token => {
		storeAuthentication(token);
		post('/user/my-info')
			.then(responseData => {
				const {data} = responseData;
				dispatch({type: 'setAuthentication'});
				dispatch({type: 'setUserData', payload: data});
				gtag('set', 'user_properties', {
					id: data._id,
					groupType: data.groupType,
				});
				const dataGA = {};
				Object.keys(data).forEach(title => {
					dataGA[`user_data_${title}`] = data[title];
				});
				gtag('event', 'set_token', dataGA);
			})
			.catch(() => {
				removeAuthentication();
			});
	};

	return (
		<UserContext.Provider
			value={{
				...state,
				resetToken,
				setToken,
				setUserData,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}

UserContextProvider.propTypes = {
	children: PropTypes.node,
};

export default UserContextProvider;
