import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import socketIoClient from 'socket.io-client';
import {usePrevious} from 'utils/hooks';
import {SocketContext, useUserContext} from './index';

function SocketContextProvider(props) {
	const {children} = props;

	const [socketCore, setSocketCore] = useState(null);
	const [socketCommunication, setSocketCommunication] = useState(null);

	const {isAuthenticated} = useUserContext();
	const previousIsAuthenticated = usePrevious(isAuthenticated);

	useEffect(() => {
		// Avoid infinity loop due to socketCore changes
		if (isAuthenticated === previousIsAuthenticated) return;

		if (isAuthenticated) {
			const env = window.ENV ?? process.env;
			const domainCore = env.REACT_APP_DOMAIN_CORE ?? 'http://localhost:60000';
			const domainCommunication = env.REACT_APP_DOMAIN_COMMUNICATION ?? 'http://localhost:60002';

			// Connect Core Service
			const newSocketCore = socketIoClient.connect(domainCore, {
				query: {
					token: localStorage.getItem('jwt'),
				},
				secure: true,
			});
			setSocketCore(newSocketCore);
			// Connect Communication Service
			const newSocketCommunication = socketIoClient.connect(domainCommunication, {
				query: {
					token: localStorage.getItem('jwt'),
				},
				secure: true,
			});
			setSocketCommunication(newSocketCommunication);
			return;
		}

		if (socketCore) {
			socketCore.disconnect();
			setSocketCore(null);
		}

		if (socketCommunication) {
			socketCommunication.disconnect();
			setSocketCommunication(null);
		}
	}, [isAuthenticated, previousIsAuthenticated, socketCore, socketCommunication]);

	return <SocketContext.Provider value={{socketCore, socketCommunication}}>{children}</SocketContext.Provider>;
}

SocketContextProvider.propTypes = {
	children: PropTypes.node,
};

export default SocketContextProvider;
