import 'dotenv/config';
import {OverlayScrollbars} from 'overlayscrollbars';
import 'overlayscrollbars/styles/overlayscrollbars.css';
import {PostHogProvider} from 'posthog-js/react';
import ReactDOM from 'react-dom';
import {onCLS, onINP, onLCP} from 'web-vitals';
import App from './App';
import * as serviceWorker from './serviceWorker';

const options = {
	api_host: 'https://us.i.posthog.com',
};

const rootElement = document.getElementById('root');

ReactDOM.render(
	<PostHogProvider apiKey={'phc_dj4mue8cKQuPtqN8iULVbUnMyy6VNANNWbbAP75WcW2'} options={options}>
		<App />
	</PostHogProvider>,
	rootElement,
);

OverlayScrollbars(document.body, {
	scrollbars: {
		autoHide: 'scroll',
	},
});

// TODO - adequar o serviceWorker para uso
serviceWorker.unregister();

function sendToGoogleAnalytics({name, delta, value, id}) {
	window.gtag('event', name, {
		value: delta,
		metric_id: id,
		metric_value: value,
		metric_delta: delta,
	});
}

onCLS(sendToGoogleAnalytics);
onINP(sendToGoogleAnalytics);
onLCP(sendToGoogleAnalytics);
